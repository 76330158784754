<template>
    <page
        id="sro-list"
        :title="$root.translate('SRO:::SRO', {}, 2)"
        :status="page_status"
        :waiting="waiting"
        :is-root="true"
        @statused="statused"
    >
        <template v-slot:title>
            <h1>
                {{ $root.translate('SRO:::SRO', {}, 2) }}
                <default-button
                    :title="$root.translate('New SRO')"
                    flavor="link"
                    @click.prevent="record_create(entity)"
                >
                    <open-icon glyph="plus" />
                </default-button>
            </h1>
        </template>
        <data-table
            id="sro-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            :page-sizes="[25, 50, 100, 0]"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @per-page="set_page_size"
            @click="record_edit(entity, $event.uuid)"
            @add="record_create(entity)"
        >
            <template
                v-slot:controls
            >
                <div>
                    <form-dropdown
                        id="status_id"
                        name="status_id"
                        :value="state.filter"
                        :options="filters"
                        empty-value=""
                        :empty-label="$root.translate('Any Status')"
                        :required="false"
                        @input="filter('status_id', $event)"
                    />
                </div>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <div class="columns">
                    <div class="column col-sm-12">
                        <h6>{{ row.title }} (#{{ row.remote_id }})</h6>
                        <h6 class="text-small mt-4 mb-2">{{ row.customer_name }}</h6>
                        <label class="form-label">
                            {{ translate("Submitted") }}: {{ row.submitted_at | nibnut.date("yyyy-MM-dd '@' HH:mm") }}
                        </label>
                    </div>
                    <div class="column col-sm-12">
                        <h6>
                            {{ type_by_id(row.type_id).name }}
                            <span
                                class="chip"
                                :style="{ backgroundColor: status_by_id(row.status_id).settings.color, color: contrast_color(status_by_id(row.status_id).settings.color) }"
                            >
                                {{ status_by_id(row.status_id).name }}
                            </span>
                        </h6>
                        <h6 class="text-small">
                            <span v-if="!!row.assignee_name">
                                <figure class="avatar avatar-xs mr-2" :data-initial="row.assignee_initials" :style="{backgroundColor: row.assignee_color}"></figure>
                                {{ row.assignee_name }}
                            </span>
                            <span v-else class="text-gray">
                                <figure class="avatar avatar-xs mr-2" data-initial="?"></figure>
                                {{ translate("Unassigned") }}
                            </span>
                        </h6>
                        <label class="form-label">
                            {{ translate("Due") }}: {{ row.due_at | nibnut.date("yyyy-MM-dd '@' HH:mm") }}
                        </label>
                    </div>
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'type_id'">{{ type_by_id(row.type_id).name }}</span>
                <span v-else-if="field === 'assignee_name'">
                    <span v-if="!!row.assignee_name">
                        <figure class="avatar avatar-sm mr-2" :data-initial="row.assignee_initials" :style="{backgroundColor: row.assignee_color}"></figure>
                        {{ row.assignee_name }}
                    </span>
                    <span v-else class="text-gray">
                        <figure class="avatar avatar-sm mr-2" data-initial="?"></figure>
                        {{ translate("Unassigned") }}
                    </span>
                </span>
                <span
                    v-else-if="field === 'status_id'"
                    class="chip"
                    :style="{ backgroundColor: status_by_id(row.status_id).settings.color, color: contrast_color(status_by_id(row.status_id).settings.color) }"
                >
                    {{ status_by_id(row.status_id).name }}
                </span>
                <span v-else-if="field.match(/_at$/i)">{{ row[field] | nibnut.date("yyyy-MM-dd '@' HH:mm") }}</span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </page>
</template>

<script type="text/javascript">
import { is_page, is_remote_data_table_source, crud_utilities } from "@/nibnut/mixins"
import { addl_misc_utilities } from "@/custom/mixins"

import {
    FormDropdown,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

const color_to_rgba = (color) => {
    const cvs = document.createElement("canvas")
    cvs.height = 1
    cvs.width = 1
    const ctx = cvs.getContext("2d")
    ctx.fillStyle = color
    ctx.fillRect(0, 0, 1, 1)
    return ctx.getImageData(0, 0, 1, 1).data
}
export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, addl_misc_utilities],
    components: {
        FormDropdown,
        DefaultButton,
        OpenIcon
    },
    methods: {
        post_load () {
            this.load_vocabularies([this.constants("vocabularies", "VOCABULARY_SRO_TYPE").id, this.constants("vocabularies", "VOCABULARY_SRO_STATUS").id])
        },
        type_by_id (type_id) {
            return this.vocabulary("VOCABULARY_SRO_TYPE").find(term => term.id === type_id) || {}
        },
        status_by_id (status_id) {
            return this.vocabulary("VOCABULARY_SRO_STATUS").find(term => term.id === status_id) || { settings: {} }
        },
        contrast_color (color_name) {
            if(!color_name) return "black"
            const rgba = color_to_rgba(color_name)
            const brightness = ((299 * rgba[0]) + (587 * rgba[1]) + (114 * rgba[2])) / 1000
            return (brightness <= 200) ? "white" : "black"
        }
    },
    computed: {
        fields () {
            return ["fieldset::list"]
        },
        filtered_rows () {
            let rows = this.rows.slice()

            rows = this.sort_rows(rows)
            if(!this.filtered_by_selection) rows = this.search_rows(rows)

            return this.page_rows(rows)
        },
        filters () {
            return [
                { id: "+", name: this.translate("Active") },
                { id: "-", name: this.translate("Inactive") },
                ...this.vocabulary("VOCABULARY_SRO_STATUS")
            ]
        }
    },
    data () {
        return {
            entity: "service_repair_order",
            min_search_length: 2,
            columns: {
                title: { label: "Title", sort: null, type: "alpha" },
                remote_id: { label: "#", sort: null, type: "numeric" },
                customer_name: { label: "Client", sort: null, type: "alpha" },
                type_id: { label: "Type", sort: false },
                assignee_name: { label: "Assignee", sort: null, type: "alpha" },
                status_id: { label: "Status", sort: false },
                submitted_at: { label: "Submitted", sort: null, type: "amount" },
                due_at: { label: "Due", sort: null, type: "amount" }
            },
            default_state: {
                per_page: 25,
                page: 1,
                sort_by: "due_at",
                sort_dir: "asc",
                filter_on: "status_id",
                filter: "+",
                archived: true,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
#sro-list {
}
</style>
